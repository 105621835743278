<template>
  <div>
    <div class="news-cover" v-if="newsData">
      <div class="news-cover--bg"></div>
      <div class="container">
        <div class="news-cover--label">{{ isEvent ? newsData.attributes.field_event_type: 'News'}}</div>
        <h1>{{ newsData.attributes.title }}</h1>
        <div class="news-cover--date">
          {{ formatDate(newsData.attributes.field_news_date) }}
        </div>
      </div>
    </div>
    <div class="container news-section-bottom" v-if="newsData">
      <div class="event-date" v-if="isEvent">
        
        <div class="event-date-block">
          <div class="date-value">
            <img width="23px" src="@/assets/icon-calendar.svg" alt="calendar">
            <div>
              <span class="date-label" v-if="newsData.attributes.field_end_date">FROM</span>
              <div>
                {{formatDate(newsData.attributes.field_news_date)}}
                <span class="time-value">{{formatTime(newsData.attributes.field_news_date)}}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="event-date-block to" v-if="newsData.attributes.field_end_date">
          <span class="date-label">TO</span>
          <div class="date-value">{{formatDate(newsData.attributes.field_end_date)}}
            <span class="time-value">{{formatTime(newsData.attributes.field_end_date)}}</span>
          </div>
        </div>
        <div class="event-date-block event-location" v-if="isEvent && newsData.attributes.field_event_location">
          <img src="@/assets/icon-location.svg" alt="">
          {{newsData.attributes.field_event_location}}
        </div>
      </div>
      <div class="event-speakers" v-if="isEvent">
        <img src="@/assets/icon-course-lecturer.svg" alt="">
        <div>
          <div class="speaker" v-for="(item,index) of newsData.attributes.field_speaker" :key="index">
            <span class="speaker--name">{{item.first}}</span>
            -
            <span class="speaker--profession">{{item.second}}</span>
          </div>
        </div>
      </div>
      <h2 class="news-subtitle">{{ newsData.attributes.field_subtitle }}</h2>
      <div
        v-if="newsData.attributes.field_shared_description"
        v-html="newsData.attributes.field_shared_description.value"
      ></div>
      <div
        v-for="(attachment, index) of attachments"
        :key="index"
        class="btn-download"
      >
        <a
          target="_blank"
          :href="`${endpoint}${attachment.data.attributes.uri.url}`"
        >
          <img src="@/assets/icon-download.svg" alt="" />
          {{ attachment.data.attributes.filename }}
        </a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    attachments: Array,
    newsData: Object,
  },
  data(){
    return {
      endpoint: process.env.VUE_APP_ENDPOINT,
    }
  },
  computed:{
    isEvent (){
      return this.newsData.type === 'node--event'
    }
  },
  methods: {
    formatDate(dateString) {
      return new Date(dateString).toLocaleDateString("en", {
        day: "numeric",
        month: "long",
        year: "numeric",
      });
    },
    formatTime(dateString) {
      return new Date(dateString).toLocaleTimeString("it",{
        'hour':"numeric",
        'minute':"numeric"
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.btn-download{
  margin-top: 3rem;
}
.date-value {
    display: flex;
    flex-direction: row;
    &>div {
      flex-direction: column;
      display: flex;
      margin-left: 18px;
    }
}
.news-cover {
  padding-top:100px;
  position: relative;
  h1 {
    font-size: 39px;
    line-height: 3rem;
    color:#fff;
  }
}

.news-cover--label{
  color: #1DC07E;
  font-weight: bold;
  font-size: 1rem;
  text-transform: capitalize;
}
.news-cover--bg{
  width: 97%;
  position: absolute;
  height: 100%;
  top: 0;
  background: #1c232f;
  border-top-right-radius: 180px;
  border-bottom-right-radius: 180px;
  z-index: -1;
}
.news-cover--date{
  color: #fff;
  font-size: 24px;
  padding-bottom: 30px;
  font-weight: 200;
}
.news-subtitle{
  word-break: break-word;
  font-size: 23px;
  line-height: 31px;
  margin-top: 40px;
}
.news-section-bottom{
  padding-bottom: 60px;
}


@media (min-width:992px) {
  .news-cover {
    padding-top:210px;
  }
}
@media (max-width:992px) {
  .news-cover--bg {
    border-top-right-radius: 60px;
    border-bottom-right-radius: 60px;
}
  .news-cover h1 {
    font-size: 24px;
    line-height: 2rem;
}
.news-cover--date {
    font-size: 20px;
}
  .event-date{
    &> div{
      margin-right: 40px;
    }
    .event-date-block {
      margin-bottom: 2rem;
      margin-bottom: 1rem;
    }
    .event-date-block.to{
      margin-left: 41px;
    }
    .event-date {
      margin-bottom: 9px;
    }
    .event-date-block.event-location{
      margin-top: 2rem;
    }
  }
}
.event-date{
  margin-top: 30px;
  display: flex;
  line-height: 1;
  margin-bottom: 30px;
  font-size: 18px;
  flex-wrap: wrap;
  align-items: flex-end;
}
.event-location{
  display: flex;
  width: 100%;
  align-items: center;
  img {
    margin-right: 10px;
    display: block;
  }
}
.date-label{
  display: inline-block;
  padding-bottom: 5px;
  color: #1DC07E;
  font-size: 12px; 
}
.time-value{
  margin-left: 8px;
  padding-left: 10px;
  display: inline-block;
  border-left: 2px solid #1DC07E;
}
@media (min-width:992px) {
  .event-location{
    width: auto;
  }
  .event-date-block{
    padding: 0 30px 0 0px;
  }
  .event-date {
    img{
      display: block;
    }
  }
}
@media (max-width:768px) {
.event-date .event-date-block.to{
    margin-right: 0px;
}
}
.event-speakers{
  display: flex;
  font-size: 18px;
  align-items: flex-start;
  img{
    margin-right: 20px;
  }
  .speaker{
    margin-bottom: 10px;
  }
  .speaker--name{
    font-weight: 500;
  }
}
</style>